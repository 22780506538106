import React from 'react';
import classnames from 'classnames';
import { Svg } from 'src/components/Svg/Svg';

import classes from './WhatsAppChat.scss';

export const WhatsAppChat: React.FC<{ isFooterElement?: boolean }> = ({ isFooterElement = false }) => {
    const phoneNumber = '441913130015';
    const message = 'Thanks for reaching out to Workwear Express! Got a question? We’re here to help.';

    return (
        <div
            className={classnames(classes.wrapper, {
                [classes.isFooterElement]: isFooterElement,
            })}
        >
            <a
                href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.icon}
            >
                <Svg role="img" name="whatsapp" />
                <span>WhatsApp Chat</span>
            </a>
        </div>
    );
};
